.hero {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 1.5rem;
	position: relative;
	margin-top: 2rem;
	margin-bottom: 1rem;
}

.heroSmall {
	margin-top: 0;
	margin-bottom: 2rem;
}

.heroSmall svg {
	width: 8rem;
	height: auto;
}

.heading {
	max-width: 25rem;
	text-align: center;
	margin: 0 calc(var(--page-margin) - var(--page-margin--small));
	letter-spacing: 0.1rem;
	font-style: italic;
}

@media (max-width: 575px) {
	.hero {
		margin-top: 0;
		margin-bottom: 0;
	}

	.heroSmall {
		margin-bottom: 1.5rem;
	}

	.hero:not(.heroSmall) svg {
		width: 12.5rem;
	}

	.heading {
		font-size: 1.5rem;
	}
}
