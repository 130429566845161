.home {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	padding: var(--page-margin--small);
	min-height: 100dvh;
}

.language {
	margin-left: auto;
}

.main {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.runner {
	display: flex;
	justify-content: center;
	margin-top: 2rem;
	margin-bottom: 1rem;
}

.heading {
	max-width: 25rem;
	text-align: center;
	margin: 0 calc(var(--page-margin) - var(--page-margin--small));
	letter-spacing: 0.1rem;
	font-style: italic;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	justify-content: space-around;
}

.link {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.container {
	position: relative;
}

.logos {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.korpen {
	transform: rotate(-10deg);
}

.korpen svg {
	display: block;
}

.image {
	position: absolute;
	z-index: -1;
}

.image img {
	width: 100%;
	height: auto;
}

.runnerImage {
	bottom: 8rem;
	right: -5rem;
	width: 16.7rem;
}

.runnerImageBroken {
	bottom: -2rem;
	left: -7.5rem;
	width: 19.8rem;
}

@media (max-width: 575px) {
	.runner {
		margin-top: 0;
		margin-bottom: 0.5rem;
	}

	.runner svg {
		width: 12.5rem;
	}

	.heading {
		font-size: 1.5rem;
	}

	.link {
		margin-top: 1rem;
		margin-bottom: 0;
	}

	.runnerImage {
		right: -18vmin;
		width: 58vmin;
	}

	.runnerImageBroken {
		left: -26vmin;
		width: 69vmin;
	}
}
