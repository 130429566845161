.toggle {
	--option-min-width: 1.5rem;
	--option-padding: 0.55rem;
	--option-overlap: 0.2rem;

	display: inline-grid;
	grid-template-columns:
		minmax(var(--option-min-width), 1fr) var(--option-overlap)
		minmax(var(--option-min-width), 1fr);
	background-color: rgba(255, 255, 255, 0.38);
	border-radius: 2rem;
	min-height: 1.5rem;
}

.checkbox:focus-visible + .toggle {
	outline: 2px solid #000;
}

.option {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75rem;
	border-radius: 2rem;
	padding-left: var(--option-padding);
	padding-right: var(--option-padding);
	grid-row: 1;
	grid-column: 1 / 3;
	z-index: 2;
	cursor: pointer;
}

.option:nth-child(2) {
	grid-column: 2 / 4;
}

.toggle::before {
	content: '';
	display: block;
	grid-column: 1 / 3;
	grid-row: 1;
	background-color: #fff;
	border-radius: 2rem;
	transition: transform 70ms ease-in-out;
	transform: translateX(calc(100% - var(--option-overlap)));
}

.toggle.checked::before {
	transform: translateX(0);
}

.checkbox {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
